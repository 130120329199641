import React, { useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Button, TextField } from '@mui/material';

const EditDomain = props => {
    const [form, setForm] = useState({
        name: '',
        user: '',
        password: '',
    });

    const { data, loading, error } = useQuery(gql`
        query($id: String!) {
            getDomain(id: $id) {
                id
                name
                user
                password
            }
        }
    `, {
        variables: {
            id: props.id,
        },
        onCompleted: data => {
            setForm({
                name: data.getDomain.name,
                user: data.getDomain.user,
                password: data.getDomain.password,
            });
        },
    });

    const [editDomain] = useMutation(gql`
        mutation($id: String! $domain: DomainInput) {
            editDomain(id: $id domain: $domain) {
                id
            }
        }
    `);

    if (loading) {
        return null;
    }

    props.setTitle(`Edit Domain ${data.getDomain.name}`);

    return <div>
        <h1>Edit Domain</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <Button onClick={() => editDomain({
            variables: {
                domain: form,
                id: props.id,
            },
        })}
        >
Edit Domain
        </Button>
    </div>;
};

export default EditDomain;
