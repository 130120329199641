/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, Card, CardContent, IconButton, MenuItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import AppOne from './AppOne';
import InstallAppWizard from './InstallAppWizard';

const Apps = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getApps {
                id
                name
                owner_user_id
                images {
                    id
                    name
                    image
                    inner_port
                }
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <h2>
Apps
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add app
            </h3>
            <div>
                <Button
                    onClick={async () => {
                        props.addWindows('Install app wizard', InstallAppWizard, {});
                    }}
                >
Install app
                </Button>
            </div>
            {data?.getApps?.map((app, index) => (
                <React.Fragment key={app.id || index}>
                    <MenuItem onClick={() => props.addWindows(app.name, AppOne, { id: app.id })}>
                        {app.name}
                    </MenuItem>
                </React.Fragment>
            ))}
        </>
    );
};

export default Apps;
