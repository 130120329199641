import { useMutation, gql } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const AddRepository = () => {
    const [addRepository] = useMutation(gql`
        mutation($repository: RepositoryInput) {
            createRepository(repository: $repository) {
                id
            }
        }
    `);

    const [form, setForm] = useState({
        name: '',
        url: '',
        user: '',
        password: '',
    });

    return <div>
        <h1>Add Repository</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="URL"
                value={form.url}
                onChange={e => setForm({ ...form, url: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <Button onClick={() => addRepository({ variables: { repository: form } })}>Add Repository</Button>
    </div>;
};

export default AddRepository;
