import { gql, useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const EditApp = props => {
    const [name, setName] = useState(props.app.name);
    const [editApp] = useMutation(gql`
        mutation($id: String!, $name: String!) {
            editApp(id: $id, name: $name)
        }
    `);
    return <div>
        <h2>
            Edit app
        </h2>
        <div>
            <TextField
                variant="standard"
                label="Name"
                value={name}
                onChange={e => setName(e.target.value)}
            />
        </div>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await editApp({ variables: { id: props.app.id, name } });
                    props.refetch();
                    props.removeWindow(props.window);
                }}
            >
                Edit app
            </Button>
        </div>
    </div>;
};

export default EditApp;
