import { gql, useQuery } from '@apollo/client';
import React from 'react';

const Certificates = props => {
    const { data, error, loading } = useQuery(gql`
        query {
            getCertificates {
                id
                domain
                private_key_path
                public_key_path
                resource {
                    id
                    name
                    resource_kind
                    resource_type
                }
            }
        }
        `);

    if (loading) return <div>Loading...</div>;

    return <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '4px' }}>
        <div>domain</div>
        <div>private_key_path</div>
        <div>public_key_path</div>
        <div>resource.id</div>
        <div>resource.name</div>
        <div>resource.resource_kind</div>
        <div>resource.resource_type</div>
        {data.getCertificates.map(certificate => <React.Fragment key={certificate.id}>
            <div>{certificate.domain}</div>
            <div>{certificate.private_key_path}</div>
            <div>{certificate.public_key_path}</div>
            <div>{certificate.resource.id}</div>
            <div>{certificate.resource.name}</div>
            <div>{certificate.resource.resource_kind}</div>
            <div>{certificate.resource.resource_type}</div>
        </React.Fragment>)}
    </div>;
};

export default Certificates;
