import { Button } from '@mui/material';
import Certificates from './Certificates';
import Dbs from './Dbs';
import Images from './Images';
import Domains from './Domains';
import Repositories from './Repositories';

const items = [
    { name: 'Databases', component: Dbs },
    { name: 'Certificates', component: Certificates },
    { name: 'Docker images', component: Images },
    { name: 'Domains', component: Domains },
    { name: 'Repositories', component: Repositories },
];

const Resources = props => <div>
    {items.map(item => <div key={item.name}>
        <Button onClick={() => props.addWindows(item.name, item.component, {})}>
            {item.name}
        </Button>
    </div>)}
</div>;

export default Resources;
