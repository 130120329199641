import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import moment from 'moment';

const Repository = props => {
    const {
        data, loading, error, refetch,
    } = useQuery(gql`
        query($id: String!) {
            getRepository(id: $id) {
                id
                name
                url
                branches
                last_fetch_date
            }
        }
    `, {
        variables: {
            id: props.id,
        },
    });

    const [fetchRepositories] = useMutation(gql`
        mutation($id: String!) {
            fetchRepository(id: $id)
        }
        `);

    if (loading) {
        return null;
    }

    props.setTitle(`Repository ${data.getRepository.name}`);

    return <div>
        <h2>
            {data.getRepository.name}
        </h2>
        <div>
            <Button
                onClick={async () => {
                    await fetchRepositories({ variables: { id: props.id } });
                    refetch();
                }}
            >
                Fetch
            </Button>
            <div>
Last fetch date:
                {moment(data.getRepository.last_fetch_date).format('YYYY-MM-DD HH:mm:ss')}
            </div>
        </div>
        <p>
            {data.getRepository.url}
        </p>
        <h4>
            Branches
        </h4>
        {data.getRepository.branches.map(branch => <div key={branch}>
            {branch}
        </div>)}
    </div>;
};

export default Repository;
