/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import {
    Button, Card, CardContent, IconButton, MenuItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Refresh } from '@mui/icons-material';
import AppOne from './AppOne';
import InstallAppWizard from './InstallAppWizard';
import Node from './Node';
import AddNode from './AddNode';

const Nodes = props => {
    const {
        loading, error, data, refetch,
    } = useQuery(gql`
        query {
            getNodes {
                id
                name
            }
        }
    `);
    useEffect(() => {
        const interval = setInterval(() => {
            // refetch();
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <h2>
Nodes
                <IconButton onClick={() => refetch()}>
                    <Refresh />
                </IconButton>
            </h2>
            {error && 'Error!'}
            {loading && 'Loading...'}
            <h3>
Add node
            </h3>
            <div>
                <Button
                    onClick={async () => {
                        props.addWindows('Install node wizard', AddNode, {});
                    }}
                >
Install node
                </Button>
            </div>
            {data?.getNodes?.map((node, index) => (
                <React.Fragment key={node.id || index}>
                    <MenuItem onClick={() => props.addWindows(node.name, Node, { id: node.id })}>
                        {node.name}
                    </MenuItem>
                </React.Fragment>
            ))}
        </>
    );
};

export default Nodes;
