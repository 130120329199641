import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Button, MenuItem } from '@mui/material';
import AddRepository from './AddRepository';
import Repository from './Repository';
import Id from '../components/Id';

const Repositories = props => {
    const { data, error, loading } = useQuery(gql`
        query {
            getRepositories {
                id
                url
                user
                password
            }
        }
      `);

    if (loading) return <div>Loading...</div>;

    return <div>
        <div>
            <h2>Repositories</h2>
        </div>
        <div>
            <Button onClick={() => props.addWindows('AddRepository', AddRepository)}>Add Repository</Button>
        </div>
        <table>
            <thead>
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        url
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.getRepositories.map(repository => <tr key={repository.id}>
                    <td>
                        <Id id={repository.id} />
                    </td>
                    <td>
                        <MenuItem onClick={() => props.addWindows('Repository', Repository, { id: repository.id })}>
                            {repository.url}
                        </MenuItem>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Repositories;
