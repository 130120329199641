import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Button } from '@mui/material';
import AddDomain from './AddDomain';
import EditDomain from './EditDomain';

const Domains = props => {
    const { data, error, loading } = useQuery(gql`
        query {
            getDomains {
                id
                name
            }
        }
      `);
    if (loading) return <div>Loading...</div>;

    return <div>
        <div>
            <Button onClick={() => props.addWindows('AddDomain', AddDomain)}>Add Domain</Button>
        </div>
        <table>
            <thead>
                <tr>
                    <th>id</th>
                    <th>name</th>
                </tr>
            </thead>
            <tbody>
                {data.getDomains.map(db => <tr key={db.id}>
                    <td>{db.id}</td>
                    <td>{db.name}</td>
                    <td>
                        <Button onClick={() => props.addWindows('EditDomain', EditDomain, { id: db.id })}>Edit</Button>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
};

export default Domains;
