import React, { useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import {
    Button, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';

const AddContainer = props => {
    const [addContainer] = useMutation(gql`
        mutation($appInstanceId: String!, $data: ContainerInput!) {
            addContainer(appInstanceId: $appInstanceId, data: $data)
        }
    `);

    const [form, setForm] = useState({
        domain_id: null,
        image_id: null,
        name: '',
        node_id: null,
        outer_port: 0,
    });

    const { data, loading, error } = useQuery(gql`
        query($id: String!) {
            getApps {
                id
                name
                images {
                id
                name
                }
            }
            getNodes {
                id
                name
            }
            getDomains {
            id
            name
            }
            getAppInstance(id: $id) {
                id
                name
            }
        }
    `, {
        variables: {
            id: props.appInstanceId,
        },
    });

    if (loading) {
        return null;
    }

    props.setTitle(`Add container to ${data.getAppInstance.name}`);

    const images = [];
    data.getApps.forEach(app => {
        app.images.forEach(image => {
            images.push(image);
        });
    });

    return <div>
        <h1>Add Container</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Outer Port"
                value={form.outer_port}
                onChange={e => setForm({ ...form, outer_port: parseInt(e.target.value) })}
                variant="standard"
            />
        </div>
        <div>
            <FormControl fullWidth>
                <InputLabel id="node">Node</InputLabel>
                <Select
                    labelId="node"
                    value={form.node_id}
                    onChange={e => setForm({ ...form, node_id: e.target.value })}
                    variant="standard"
                >
                    {data.getNodes.map(node => <MenuItem key={node.id} value={node.id}>{node.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
        <div>
            <FormControl fullWidth>
                <InputLabel id="image">Image</InputLabel>
                <Select
                    labelId="image"
                    value={form.image_id}
                    onChange={e => setForm({ ...form, image_id: e.target.value })}
                    variant="standard"
                >
                    {images.map(image => <MenuItem key={image.id} value={image.id}>{image.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
        <div>
            <FormControl fullWidth>
                <InputLabel id="domain">Domain</InputLabel>
                <Select
                    labelId="domain"
                    value={form.domain_id}
                    onChange={e => setForm({ ...form, domain_id: e.target.value })}
                    variant="standard"
                >
                    <MenuItem value={null}>None</MenuItem>
                    {data.getDomains.map(domain => <MenuItem key={domain.id} value={domain.id}>{domain.name}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
        <Button
            onClick={() => addContainer({ variables: { appInstanceId: props.appInstanceId, data: form } })}
            variant="contained"
        >
Add Container
        </Button>
    </div>;
};

export default AddContainer;
