/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import copy from 'copy-to-clipboard';

import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState } from 'react';

const Id = props => {
    const [show, setShow] = useState(false);

    return <>
        <Tooltip title={props.id}>
            <InfoOutlined
                style={{ cursor: 'pointer' }}
                fontSize="small"
                onClick={() => {
                    copy(props.id);
                    setShow(!show);
                }}
            />
        </Tooltip>
        {show && props.id}
    </>;
};

export default Id;
