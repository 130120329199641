import { gql, useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const EditInstance = props => {
    const [instance, setInstance] = useState(props.instance.name);
    const [editInstance] = useMutation(gql`
        mutation($id: String!, $name: String!) {
            editAppInstance(id: $id, name: $name)
        }
    `);
    return <div>
        <h2>
            Edit instance
        </h2>
        <div>
            <TextField
                variant="standard"
                label="Name"
                value={instance}
                onChange={e => setInstance(e.target.value)}
            />
        </div>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await editInstance({ variables: { id: props.instance.id, name: instance } });
                    props.refetch();
                    props.removeWindow(props.window);
                }}
            >
                Edit instance
            </Button>
        </div>
    </div>;
};

export default EditInstance;
