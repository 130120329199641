/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

const Events = props => <div style={{ backgroundColor: 'white' }}>
    {props.events.current.filter(
        event => event.type !== 'DockerEvent' || true,
    ).map((event, i) => <div style={{ display: 'flex' }} key={i}>
        <div>{event.type}</div>
        <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{event.type === 'shellCommandOutput' ? event.data : JSON.stringify(event.data, null, 2)}</pre>
    </div>)}
</div>;

export default Events;
