import { gql, useMutation, useQuery } from '@apollo/client';
import {
    Button, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';

const EditContainer = props => {
    const [container, setContainer] = useState({});
    const [editContainer] = useMutation(gql`
        mutation($id: String!, $data: ContainerInput!) {
            editContainer(id: $id, data: $data)
        }
    `);

    const { data, loading, error } = useQuery(gql`
        query($id: String! $appInstanceId: String!) {
            getContainer(id: $id) {
                id
                name
                outer_port
                image_id
                node_id
                domain_id
            }
            getImages {
                id
                name
            }
            getNodes {
                id
                name
            }
            getDomains {
            id
            name
            }
            getAppInstance(id: $appInstanceId) {
                id
                name
            }
        }
    `, {
        variables: {
            id: props.id,
            appInstanceId: props.appInstanceId,
        },
        onCompleted: data => {
            setContainer(data.getContainer);
        },
    });

    if (loading) return <div>Loading...</div>;

    const images = data.getImages;

    return <div>
        <h2>
            Edit container
        </h2>
        <div>
            <TextField
                variant="standard"
                label="Name"
                value={container.name}
                onChange={e => setContainer({ ...container, name: e.target.value })}
            />
            <TextField
                variant="standard"
                label="Outer port"
                value={container.outer_port}
                onChange={e => setContainer({ ...container, outer_port: parseInt(e.target.value) })}
            />
            <FormControl>
                <InputLabel id="image">Image</InputLabel>
                <Select
                    labelId="image"
                    value={container.image_id}
                    onChange={e => setContainer({ ...container, image_id: e.target.value })}
                >
                    {data.getImages.map(image => <MenuItem key={image.id} value={image.id}>{image.name}</MenuItem>)}
                </Select>
            </FormControl>
            <div>
                <FormControl fullWidth>
                    <InputLabel id="node">Node</InputLabel>
                    <Select
                        labelId="node"
                        value={container.node_id}
                        onChange={e => setContainer({ ...container, node_id: e.target.value })}
                        variant="standard"
                    >
                        {data.getNodes.map(node => <MenuItem key={node.id} value={node.id}>{node.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth>
                    <InputLabel id="domain">Domain</InputLabel>
                    <Select
                        labelId="domain"
                        value={container.domain_id}
                        onChange={e => setContainer({ ...container, domain_id: e.target.value })}
                        variant="standard"
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {data.getDomains.map(domain => <MenuItem key={domain.id} value={domain.id}>{domain.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
        </div>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await editContainer({
                        variables: {
                            id: props.container.id,
                            data: {
                                name: container.name,
                                outer_port: container.outer_port,
                                image_id: container.image_id,
                                node_id: container.node_id,
                                domain_id: container.domain_id,
                            },
                        },
                    });
                    props.refetch();
                    props.removeWindow(props.window);
                }}
            >
                Edit container
            </Button>
        </div>
    </div>;
};

export default EditContainer;
