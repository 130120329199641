import { gql, useQuery } from '@apollo/client';
import React from 'react';

const Dbs = props => {
    const { data, error, loading } = useQuery(gql`
        query {
            getDatabases {
            id
            db_host
            db_name
            db_password
            db_protocol
            db_user
                resource {
                    id
                    name
                    resource_kind
                    resource_type
                }
            }
        }
      `);

    if (loading) return <div>Loading...</div>;

    return <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '4px' }}>
        <div>db_host</div>
        <div>db_name</div>
        <div>db_password</div>
        <div>db_protocol</div>
        <div>db_user</div>
        <div>resource.id</div>
        <div>resource.name</div>
        <div>resource.resource_kind</div>
        <div>resource.resource_type</div>
        {data.getDatabases.map(db => <React.Fragment key={db.id}>
            <div>{db.db_host}</div>
            <div>{db.db_name}</div>
            <div>{db.db_password}</div>
            <div>{db.db_protocol}</div>
            <div>{db.db_user}</div>
            <div>{db.resource.id}</div>
            <div>{db.resource.name}</div>
            <div>{db.resource.resource_kind}</div>
            <div>{db.resource.resource_type}</div>
        </React.Fragment>)}
    </div>;
};

export default Dbs;
