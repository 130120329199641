/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CopySettings from '../components/CopySettings';
import DeviceDialog from '../components/DeviceDialog';

const RepositoryDialog = props => {
    const [containerDeviceData, setContainerDeviceData] = useState({
        repository: '',
    });
    const [setContainerDeviceRepository] = useMutation(gql`
        mutation($containerId: String, $deviceId: String, $repository: ContainerDeviceRepositoryInput) {
            setContainerDeviceRepository(container_id: $containerId, device_id: $deviceId, repository: $repository)
        }
    `);
    return <DeviceDialog
        {...props}
        deviceTypeQuery={`getContainerDevicRepository(container_id: $containerId, device_id: $deviceId) {
            repository
        }`}
        envFields={['repository']}
        auxFields={[]}
        deviceTypeField={Object.keys(containerDeviceData).map(key => <div key={key}>
            {key}
            <TextField
                value={containerDeviceData[key]}
                onChange={e => {
                    const newData = JSON.parse(JSON.stringify(containerDeviceData));
                    newData[key] = e.target.value;
                    setContainerDeviceData(newData);
                }}
                variant="standard"
            />
        </div>)}
        setDeviceTypeData={data => {
            const _data = JSON.parse(JSON.stringify(containerDeviceData));
            Object.keys(data.getContainerDeviceDomain).forEach(d => {
                if (_data[d] !== undefined) {
                    _data[d] = data.getContainerDeviceDomain[d];
                }
            });
            setContainerDeviceData(_data);
        }}
        setDeviceType={() => setContainerDeviceRepository({
            variables: {
                containerId: props.containerId,
                deviceId: props.deviceId,
                domain: { domain: containerDeviceData.domain },
            },
        })}
    />;
    const [loadDevice] = useLazyQuery(gql`
        query($deviceId: String, $containerId: String) {
            getDevice(id: $deviceId) {
                id
                name
                device_type_id
            }
            getContainerDeviceRepository(container_id: $containerId, device_id: $deviceId) {
                repository
            }
            getContainerDeviceAuxOptions(container_id: $containerId, device_id: $deviceId) {
                container_option_value
                device_option_name
            }
            getContainerDeviceEnvOptions(container_id: $containerId device_id: $deviceId) {
                container_env_name
                device_option_name
            }
        }
    `, { variables: { deviceId: props.deviceId, containerId: props.containerId } });
    const [containerDeviceEnvData, setContainerDeviceEnvData] = useState({
        repository: '',
    });
    useEffect(() => {
        if (props.update) {
            loadDevice({
                onCompleted: data => {
                    const _data = JSON.parse(JSON.stringify(containerDeviceData));
                    _data.repository = data.getContainerDeviceRepository.repository;
                    setContainerDeviceData(_data);
                    const envData = JSON.parse(JSON.stringify(containerDeviceEnvData));
                    data.getContainerDeviceEnvOptions.forEach(d => {
                        envData[d.device_option_name] = d.container_env_name;
                    });
                    setContainerDeviceEnvData(envData);
                },
            });
        }
    }, [props.open]);
    const [addDevice] = useMutation(gql`
        mutation($containerId: String, $input: ContainerDeviceInput) {
            addDeviceToContainer(container_id: $containerId, input: $input)
        }
    `);
    const [setContainerDeviceEnvOptions] = useMutation(gql`
        mutation($containerId: String, $deviceId: String, $options: [ContainerDeviceEnvOptionInput]) {
            setContainerDeviceEnvOptions(container_id: $containerId, device_id: $deviceId, options: $options)
        }
    `);

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{props.update ? 'Edit repository' : 'Add repository'}</DialogTitle>
        <DialogContent>
            <h4>Fields</h4>
            <CopySettings
                deviceId={props.deviceId}
                containerId={props.containerId}
                containerDeviceEnvData={containerDeviceEnvData}
                setContainerDeviceEnvData={setContainerDeviceEnvData}
            />
            {Object.keys(containerDeviceData).map(key => <div key={key}>
                {key}
                <TextField
                    value={containerDeviceData[key]}
                    onChange={e => {
                        const newData = JSON.parse(JSON.stringify(containerDeviceData));
                        newData[key] = e.target.value;
                        setContainerDeviceData(newData);
                    }}
                    variant="standard"
                />
            </div>)}
            <h4>Envs</h4>
            {Object.keys(containerDeviceEnvData).map(key => <div key={key}>
                {key}
                <TextField
                    value={containerDeviceEnvData[key]}
                    onChange={e => {
                        const newData = JSON.parse(JSON.stringify(containerDeviceEnvData));
                        newData[key] = e.target.value;
                        setContainerDeviceEnvData(newData);
                    }}
                    variant="standard"
                />
            </div>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Cancel
            </Button>
            <Button
                onClick={async () => {
                    if (!props.update) {
                        await addDevice({
                            variables: {
                                containerId: props.containerId,
                                input: {
                                    id: props.deviceId,
                                    aux_parameters: [],
                                    env_parameters: Object.keys(containerDeviceEnvData).map(key => ({
                                        key,
                                        value: containerDeviceEnvData[key],
                                    })),
                                },
                            },
                        });
                    }
                    await setContainerDeviceRepository({
                        variables: {
                            containerId: props.containerId,
                            deviceId: props.deviceId,
                            repository: { repository: containerDeviceData.repository },
                        },
                    });
                    await setContainerDeviceEnvOptions({
                        variables: {
                            containerId: props.containerId,
                            deviceId: props.deviceId,
                            options: Object.keys(containerDeviceEnvData).map(key => ({
                                key,
                                value: containerDeviceEnvData[key],
                            })),
                        },
                    });
                    props.onClose();
                }}
                color="primary"
            >
                Save
            </Button>
        </DialogActions>
    </Dialog>;
};

export default RepositoryDialog;
