import { useMutation, gql } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const AddNode = () => {
    const [addNode] = useMutation(gql`
        mutation($node: NodeInput) {
            createNode(node: $node) {
                id
            }
        }
    `);

    const [form, setForm] = useState({
        name: '',
        host: '',
        user: '',
        password: '',
    });

    return <div>
        <h1>Add Node</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Host"
                value={form.host}
                onChange={e => setForm({ ...form, host: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <Button onClick={() => addNode({ variables: { node: form } })}>Add Node</Button>
    </div>;
};

export default AddNode;
