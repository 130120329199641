import { gql, useQuery } from '@apollo/client';
import React from 'react';

const Images = props => {
    const { data, error, loading } = useQuery(gql`
        query {
            getImages {
                id
                name
                image
            }
        }
      `);

    if (loading) return <div>Loading...</div>;

    return <div style={{ display: 'grid', gridTemplateColumns:'repeat(5, 1fr)', gap: '4px' }}>
        <div>id</div>
        <div>url</div>
        {data.getRepositories.map(repository => <React.Fragment key={repository.id}>
            <div>{repository.id}</div>
            <div>{repository.url}</div>
        </React.Fragment>)}
    </div>;
};

export default Images;
