import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button, TextField } from '@mui/material';

const AddDomain = () => {
    const [addDomain] = useMutation(gql`
        mutation($domain: DomainInput) {
            createDomain(domain: $domain) {
                id
            }
        }
    `);

    const [form, setForm] = useState({
        name: '',
        user: '',
        password: '',
    });

    return <div>
        <h1>Add Domain</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="User"
                value={form.user}
                onChange={e => setForm({ ...form, user: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Password"
                value={form.password}
                onChange={e => setForm({ ...form, password: e.target.value })}
                variant="standard"
            />
        </div>
        <Button onClick={() => addDomain({ variables: { domain: form } })}>Add Domain</Button>
    </div>;
};

export default AddDomain;
