import { gql, useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const EditImage = props => {
    const [image, setImage] = useState(JSON.parse(JSON.stringify(props.image)));
    const [editImage] = useMutation(gql`
        mutation($id: String!, $name: String! $image: String! $inner_port: Int!) {
            editImage(id: $id, name: $name, image: $image, inner_port: $inner_port)
        }
    `);
    return <div>
        <h2>
            Edit image
        </h2>
        <div>
            <TextField
                variant="standard"
                label="Name"
                value={image.name}
                onChange={e => setImage({ ...image, name: e.target.value })}
            />
            <TextField
                variant="standard"
                label="Image"
                value={image.image}
                onChange={e => setImage({ ...image, image: e.target.value })}
            />
            <TextField
                variant="standard"
                label="Inner port"
                value={image.inner_port}
                onChange={e => setImage({ ...image, inner_port: parseInt(e.target.value) })}
            />
        </div>
        <div>
            <Button
                variant="contained"
                onClick={async () => {
                    await editImage({
                        variables: {
                            id: props.image.id,
                            name: image.name,
                            image: image.image,
                            inner_port: image.inner_port,
                        },
                    });
                    props.refetch();
                    props.removeWindow(props.window);
                }}
            >
                Edit app
            </Button>
        </div>
    </div>;
};

export default EditImage;
