import { useMutation, gql, useQuery } from '@apollo/client';
import {
    Button, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';

const AddImage = props => {
    const { data, loading, error } = useQuery(gql`
        query($appId: String!) {
            getApp(id: $appId) {
                id
                name
            }
            getRepositories {
                id
                url
                branches
            }
        }
    `, {
        variables: {
            appId: props.appId,
        },
    });
    const [addImage] = useMutation(gql`
        mutation($appId: String!, $image: ImageInput!) {
            addImage(appId: $appId, image: $image)
        }
    `);

    const [form, setForm] = useState({
        name: '',
        image: '',
        repository_id: null,
        branch: '',
        inner_port: 0,
    });

    if (loading) {
        return null;
    }

    props.setTitle(`Add image to ${data.getApp.name}`);

    return <div>
        <h1>Add Image</h1>
        <div>
            <TextField
                label="Name"
                value={form.name}
                onChange={e => setForm({ ...form, name: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Image"
                value={form.image}
                onChange={e => setForm({ ...form, image: e.target.value })}
                variant="standard"
            />
        </div>
        <div>
            <TextField
                label="Inner Port"
                value={form.inner_port}
                onChange={e => setForm({ ...form, inner_port: parseInt(e.target.value) })}
                variant="standard"
            />
        </div>
        <div>
            <Select
                label="Repository"
                value={form.repository_id}
                onChange={e => setForm({ ...form, repository_id: e.target.value })}
                variant="standard"
            >
                <MenuItem value={null}>None</MenuItem>
                {data.getRepositories.map(repository => <MenuItem key={repository.id} value={repository.id}>{repository.url}</MenuItem>)}
            </Select>
        </div>
        {form.repository_id && <div>
            <Select
                label="Branch"
                value={form.branch}
                onChange={e => setForm({ ...form, branch: e.target.value })}
                variant="standard"
            >
                <MenuItem value="">None</MenuItem>
                {data.getRepositories.find(repository => repository.id === form.repository_id).branches.map(branch => <MenuItem key={branch} value={branch}>{branch}</MenuItem>)}
            </Select>
        </div>}
        <div>
            <Button
                onClick={() => addImage({
                    variables: {
                        appId: props.appId,
                        image: form,
                    },
                })}
                variant="contained"
            >
                    Add Image
            </Button>
        </div>
    </div>;
};

export default AddImage;
