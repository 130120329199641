/* License: Apache 2.0. https://www.apache.org/licenses/LICENSE-2.0 */

import { useEffect, useState } from 'react';

const useFetch = (url, options, dependencies) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await fetch(url, {
                ...options,
            });
            res.status !== 200 && setError(true);
            const json = await res.json();
            setResponse(json);
        } catch (error) {
            console.log(error);
            setError(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, dependencies);

    return {
        data: response, error, loading, refetch: fetchData,
    };
};

const token = window.localStorage.getItem('megapolos.token');
export const server = window.localStorage.getItem('megapolos.server') || 'localhost:5100';

const apiUrl = (endpoint, params) => `http://${server}${endpoint}`;

const useApi = (endpoint, params) => useFetch(apiUrl(endpoint, params), {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token,
    },
    body: JSON.stringify(params),
}, []);

const fetchApi = async (endpoint, params) => {
    const result = await window.fetch(apiUrl(endpoint, params), {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token,
        },
        body: JSON.stringify(params),
    });
    const resultJson = await result.json();
    return resultJson;
};

export { useApi, fetchApi };
