import { gql, useMutation } from '@apollo/client';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

const InstallAppWizard = () => {
    const [appForm, setAppForm] = useState({ name: '', images: [{ name: '', image: '', inner_port: 0 }] });
    const [installApp] = useMutation(gql`
    mutation($input: AppInput!) {
        installApp(input: $input)
    }
`);
    return <div>
        <h2>Install app wizard</h2>
        {appForm.images.length !== 1 && <div>
            <TextField
                label="Name"
                value={appForm.name}
                onChange={e => {
                    const newAppForm = JSON.parse(JSON.stringify(appForm));
                    newAppForm.name = e.target.value;
                    setAppForm(newAppForm);
                }}
                variant="standard"
            />
        </div>}
        <div>images</div>
        {appForm.images.map((form, index) => (
            <div key={index}>
                <TextField
                    label="Name"
                    value={form.name}
                    onChange={e => {
                        const newAppForm = JSON.parse(JSON.stringify(appForm));
                        newAppForm.images[index].name = e.target.value;
                        if (index === 0) {
                            newAppForm.name = e.target.value;
                        }
                        setAppForm(newAppForm);
                    }}
                    variant="standard"
                />
                <TextField
                    label="Image"
                    value={form.image}
                    onChange={e => {
                        const newAppForm = JSON.parse(JSON.stringify(appForm));
                        newAppForm.images[index].image = e.target.value;
                        setAppForm(newAppForm);
                    }}
                    variant="standard"
                />
                <TextField
                    type="number"
                    label="inner_port"
                    value={form.inner_port}
                    onChange={e => {
                        const newAppForm = JSON.parse(JSON.stringify(appForm));
                        newAppForm.images[index].inner_port = parseInt(e.target.value);
                        setAppForm(newAppForm);
                    }}
                    variant="standard"
                />
                <Button
                    onClick={() => {
                        const newAppForm = JSON.parse(JSON.stringify(appForm));
                        newAppForm.images.splice(index, 1);
                        setAppForm(newAppForm);
                    }}
                >
Delete
                </Button>
            </div>
        ))}
        <Button
            onClick={() => {
                const newAppForm = JSON.parse(JSON.stringify(appForm));
                newAppForm.images.push({ name: '', image: '', inner_port: 0 });
                setAppForm(newAppForm);
            }}
        >
Add image
        </Button>
        <div>
            <Button
                onClick={async () => {
                    await installApp({
                        variables: {
                            input: appForm,
                        },
                    });
                }}
            >
Install
            </Button>
        </div>
    </div>;
};

export default InstallAppWizard;
